<template>
  <div id="news-article">
    <table-action-row
      :filterList="listQuery"
      canFilterDate
      @onFilterChange="value => { listQuery = value; handleFilter() }"
      :handleAction="checkAccessRight('add') ? () => goTo('/bo/article/detail') : null"
      actionButtonCode="ADD_ARTICLE"
      actionButtonIcon="icon-add"
    />

    <el-alert
    v-if="!checkAccessRight('add')"
    :title="$t('ARTICLE_RESTRICTION_INFO')"
    type="info"
    class="info-bar"
    effect="dark"></el-alert>

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        prop="name"
        :label="$t('AUTHOR')"
        >
        <template slot-scope="{row}">
          {{ getFullName(row.user) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('TITLE')"
      >
        <template slot-scope="{row}">
          {{ row.title | translate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('SHORT_DESC')"
        min-width="200px"
      >
        <template slot-scope="{row}">
          {{ row.description | translate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="date"
        :label="$t('PUBLISH_DATE')"
        width="100"
      >
        <template slot-scope="{row}">
          {{ moment(row.publishDate).format(DATE_FORMAT) }} 
        </template>
      </el-table-column>
      <el-table-column
        prop="category"
        :label="$t('CATEGORY')"
      >
        <template slot-scope="{row}">
          {{ row.category | translate }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('ARTICLE_LINK')" width="150">
        <template slot-scope="{ row }">
          <el-link
            type="primary"
            @click="goToArticle(row, true)"
          >
            {{ $t("CLICK_TO_OPEN") }}
          </el-link>
          <i
            class="custom-icon mini pointer icon-copy"
            @click="copyArticleLink(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="120"
      >
        <template slot-scope="{row}">
          <div>
            <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="goTo(`/bo/article/detail/${row.id}`)"
                >
                  {{ checkAccessRight('edit') ? $t("EDIT") : $t("VIEW") }}
                </el-dropdown-item>
                <el-dropdown-item 
                  v-if="checkAccessRight('archive')" 
                  @click.native="handleArchive(row.id)"
                >
                  <el-divider class="no-margin" />
                  {{ $t("ARCHIVE") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import { PAGE_SIZES } from "@/constants";
import { generalMixin } from "@/utils/general-mixin.js"
import { articleMixin } from '@/utils/_mixins/article'
import { getArticleList, archiveArticle } from "@/api/article"
import moment from "moment"
import { mapGetters } from "vuex"

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  showAllLanguage: true,
  dateFrom: null,
  dateTo: null,
  showSuspended: true
};

export default {
  name: "NewsArticle",
  mixins: [generalMixin, articleMixin],
  components: {
    Pagination,
    TableActionRow
  },
  data() {
    return {
      moment: moment,
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isLoading: false,
      total: 0,      
      list: [],
    }
  },
  computed: {
    ...mapGetters(["user"]),
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    }
  },
  methods: {
    tableRowClassName({row}) {
      if (this.isOwner(row.user.id)) {
        return 'owner-row';
      }
      return '';
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      if (!this.isBOUser) {
        this.listQuery = {...this.listQuery,user:{id : this.user.id}}
      }
      
      this.handleFilter();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    },
    getList() {
      // NOTE - Member can VIEW own article only (filter by backend)
      this.isLoading = true
      getArticleList(this.listQuery).then(res => {
        if (res.status != 'ok') return
        // console.log('Got: ', res)
        this.list = cloneDeep(res.result.list)
        this.total = res.result.total
      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
    handleArchive(id) {
      this.$confirm(this.$t("ARCHIVE_CONFIRMATION"), this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("ARCHIVE"),
        cancelButtonText: this.$t("CANCEL"),
        type: 'warning'
      })
      .then(() =>{
        archiveArticle(id).then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("UPDATE_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })          
        }
      })
        .catch(this.handleError)
        .finally(() => this.getList())
      }).catch(() => {});
    }
  },
  mounted() {
    this.handleReset()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/mixin/table.scss";
#news-article {
  position: relative;
  @include newsily-bo-table;
  .action-button {
    float: right;
  }
  .el-table .owner-row {
    background: #f4f4f4;
  }
  .info-bar {
    width: 350px;
    float: left;
    position: fixed;
    top: 170px;
    left: 33%;
  }
}
.mobile {
  #news-article .info-bar {
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
  }
}
</style>